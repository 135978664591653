<template>
    <div class="locale-changer">

      <v-overlay v-model="isSwitching">

        {{ $t('core.text.switching_language') }}
        <v-progress-linear indeterminate></v-progress-linear>


      </v-overlay>

      <v-menu top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :small="small"
          >
            <v-icon :small="small">mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-list dense class="ma-0 pa-0">
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="selectLocale(item)"
          >
            <v-list-item-title>
              <flag :iso="item.iso" class="mr-3" style="display: inline;"></flag>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>



    </div>
</template>

<script>
import { sessionStore } from '@/store/pinia/sessionStore'
    export default {
        name: 'langSwitch',

      props: {

          small: Boolean

      },

      setup() {

        const session = sessionStore();

        return {
          // you can return the whole store instance to use it in the template
          session,
        }

      },
        data () {
            return {
                items: [
                    { text: 'Deutsch', value:'de_DE', iso: 'de' },
                    { text: 'Deutsch (Schweiz)', value:'de_CH', iso: 'ch' },
                    { text: 'English', value:'en_EN', iso: 'gb' },
                    { text: 'Espaõl', value:'es_ES', iso: 'es' },
                    { text: 'Francais', value:'fr_FR', iso: 'fr' },
                    { text: 'Italiano', value:'it_IT', iso: 'it' },
                ],
                selectedLocale: null,
                isSwitching: false
            }
        },

        methods: {

          selectLocale: function(locale){

            let self = this;

            self.isSwitching = true;
            self.selectedLocale = locale;
            self.session.changeLocale(locale.value);
            self.session.state = 'initializing';
            setTimeout(function(){

              window.location.reload();
              self.isSwitching = false;

            },500);


          }

        },

        mounted: function(){

            this.selectedLocale = this.items.find(itm => itm.value === this.session.locale);

        },


    }
</script>



<style lang="scss" scoped>

.locale-changer {
  display: inline-block;
}


</style>
