import { defineStore } from 'pinia'
import EventService from '@/services/APIService.js'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const sessionStore = defineStore('PlingSessionStore', {
    state: () => {
        return {
            // all these properties will have their type inferred automatically

            state: 'initializing',
            authenticated: false,
            endpoint: null,
            server: null,
            servers: null,
            is_test_server: false,
            auth_token: null,
            user_data: null,
            license: null,

            background_jobs: [],
            errors: null,

            export_jobs: [],
            breadcrumb : [],


            primary_menu: false,
            server_selector: false,
            dark_mode: false,

            app_bar: true,

            locale_presets: [],
            entity_locales: [],
            locale: null,
            client_version: process.env.VUE_APP_GIT_HASH,
            server_version: null

        }
    },
    getters: {

        getUserRoles(state){

            if(state.user_data == null){return [];}
            return state.user_data.roles_inherited;

        },

        isAdmin(state){


            if(state.user_data == null){return false;}

            if(state.user_data.roles_inherited.indexOf('admin') !== -1){

                return true;

            }


            return false;

        }

    },

    actions: {



        changeLocale(locale){

            this.locale = locale;
            localStorage.setItem('pling:locale',locale);
            //sessionStorage.clear();
            EventService.defaults.headers.common['Override-Locale'] = locale;


            if(locale == 'de_DE'){

                require('moment/locale/de');


            }

        },

        init(){

            this.server = localStorage.getItem('pling:server');
            this.servers = JSON.parse(localStorage.getItem('pling:servers'));

            if(this.servers === null){this.servers = [];}

            this.auth_token = localStorage.getItem('pling:token');

            this.locale = localStorage.getItem('pling:locale');
            if(this.locale === null){this.locale = 'de_DE';}

            this.changeLocale(this.locale);


            this.locale_presets = JSON.parse(localStorage.getItem('pling:locale_presets'));
            if(this.locale_presets === null){this.locale_presets = [];}

            this.dark_mode = localStorage.getItem('pling:darkmode');
            if(this.dark_mode === 'true'){this.dark_mode = true;} else {this.dark_mode = false;}


            let primary_menu =  localStorage.getItem('pling:primary_nav_open');
            if(primary_menu == 'true' || primary_menu == null){this.primary_menu = true;}

            let server_selector = localStorage.getItem('pling:show_server_selector');
            if(server_selector == 'true'){this.server_selector = true;}


            EventService.defaults.baseURL = this.server;
            EventService.defaults.headers.common['Token'] = this.auth_token;




            this.setServer(this.server);


            this.ping();



        },

        toggleServerSelector(){

            let self = this;
            self.server_selector = !self.server_selector;
            localStorage.setItem('pling:show_server_selector',self.server_selector);


        },

        togglePrimaryMenu(state){


            let self = this;


            if(state === undefined){

                self.primary_menu = !self.primary_menu;


            } else {
                self.primary_menu = state;
            }


            localStorage.setItem('pling:primary_nav_open',self.primary_menu);



        },

        setServer(server){


            var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;


            if(server == null){

                this.endpoint = null;
                return;

            }


            if(process.env.VUE_APP_FORCED_SERVER){

                this.server = process.env.VUE_APP_FORCED_SERVER;
                this.endpoint = server;

            } else {

                this.server = server;

                if(!regexp.test(server)){
                    this.endpoint = 'https://'+server;
                } else {
                    this.endpoint = server;
                }

            }


            localStorage.setItem('pling:server',this.server);
            EventService.defaults.baseURL = this.endpoint;

        },

        addServer(server){


            let self = this;

            let index = self.servers.indexOf(server);

            if(index === -1){

                self.servers.push(server);

                localStorage.setItem('pling:servers',JSON.stringify(self.servers));

            }



        },

        ping(){

            let self = this;

            if(this.endpoint === null){

                self.state = 'ready'; return;

            }



            EventService.get('/auth/ping') .then(function (result) {

                self.is_test_server = result.data.is_test_server;
                self.server_version = result.data.api_version;

                if(result.data.payload !== true){
                    self.state = 'ready';
                }
                else {
                    self.getUserData();
                    self.state = 'authenticated';
                }

            }).catch(function (error) {
                self.state = 'ready';
            })


        },


        getUserData(){


            let self = this;




            EventService.post('/api/v3/user/me',{expand: ["shops","roles","sessions","api_keys","ip_whitelist","channels"]}).then((response) => {

                self.user_data = response.data.payload;

            });

        },

        getBackgroundJobs(){

            let self = this;

            let params = {
                "object": "export-job",
                "scope": [
                    "results"
                ],

                params: {

                        page_size: 100

                }

            };


            EventService.post('/api/v3/search',params).then((response) => {

                self.export_jobs = response.data.payload.results;

            });

        },

        async getEntityLocales(){


            let self = this;

            return EventService.get('api/erp/v2/info/locales').then((result) => {

                self.entity_locales = result.data.payload;

            });


        },

        setLocalePreset(type,preset){


            let self = this;


            let index = self.locale_presets.findIndex(pr => pr.type === type);

            if(index === -1){
                self.locale_presets.push({

                    type: type,
                    preset: preset

                });

            } else {


                self.locale_presets[index] = {

                    type: type,
                    preset: preset

                }

            }


            localStorage.setItem('pling:locale_presets',JSON.stringify(self.locale_presets));


        },

        getLocalePreset(type){

            let self = this;

            let preset = self.locale_presets.find(pr => pr.type === type);


            if(!preset && type !== 'any'){

                return self. getLocalePreset('any');

            }

            return preset.preset;

        },


        signOut(){

            let self = this;

            self.state = 'deauthenticating';

            EventService.post('/auth/logout').then((result) => {

                localStorage.removeItem('pling:token');
                sessionStorage.clear();

                self.$reset();
                self.init();

            });


        },

        signIn(credentials){

            let self = this;

            self.setServer(credentials.server);

            if(self.endpoint === null){return false;}

            self.state = 'authenticating'
            EventService.defaults.baseURL = this.endpoint;

            localStorage.setItem('pling.credentials.last_login',credentials.login);

            EventService.post('/auth/login', credentials).then(function(response){

                let token = response.data.payload;

                if(token !== false){

                    self.auth_token = token;
                    localStorage.setItem('pling:token',token);

                    EventService.defaults.headers.common['Token'] = token;
                    self.getUserData();
                    self.addServer(self.server);
                    self.state = 'authenticated';

                } else {

                    self.state = 'ready';

                }





            }) .catch(function (error) {
                self.state = 'ready'
            });







        },

        toggleDarkMode(){


            this.dark_mode = !this.dark_mode;
            localStorage.setItem('pling:darkmode',this.dark_mode);




        }

    },


})
