<template>


  <div>

    <v-navigation-drawer
        v-model="session.primary_menu"
        fixed
        app
        width="300"
        class="primary-navigation-drawer"
        @click="session.togglePrimaryMenu()"
        @input="session.togglePrimaryMenu($event)"

    >

      <div class="d-flex flex-column" style="height: 100%;">
        <v-list class="ma-0 pa-0" dense>

          <v-list-item two-line class="pl-3">

            <v-list-item-avatar size="32" tile>
              <v-img :src="require('@/assets/icon.png')"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>PLING</v-list-item-title>
              <v-list-item-subtitle>
              {{ session.server }}
              </v-list-item-subtitle>

            </v-list-item-content>

          </v-list-item>

          <template v-for="(item,index) in menu" >

            <v-list-group v-if="item.children" :key="item.index" :prepend-icon="item.icon">


              <template v-slot:activator>

                <v-list-item-content>

                  <v-list-item-title v-text="item.text"></v-list-item-title>

                </v-list-item-content>

              </template>

              <div v-for="subItem in item.children" :key="subItem.text">

                <v-list-item  router :to="subItem.to">
                  <v-list-item-content>
                    <v-list-item-title v-text="subItem.text"></v-list-item-title>

                  </v-list-item-content>
                </v-list-item>

              </div>

            </v-list-group>

            <v-list-item v-else router :to="item.to" :disabled="item.to==null" :key="index">

              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}
                <v-chip label x-small class="ml-3" v-if="item.to==null" style="text-transform:uppercase;">
                  {{ $t('common.coming_soon') }}
                </v-chip>
              </v-list-item-title>

            </v-list-item>



          </template>

        </v-list>

      </div>

    </v-navigation-drawer>

  </div>


</template>


<script>

import { sessionStore } from '@/store/pinia/sessionStore'




export default {

  name: 'PrimaryNavigation',
  components: {},
  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },

  computed: {


    menu: function(){

      let self = this;
      let menu = JSON.parse(JSON.stringify(self.items));


      menu = menu.filter(itm => itm.requires_role === undefined || self.user_has_role(itm.requires_role));

      menu.forEach(function (menu_item, index) {

        if (menu_item.children) {

          menu_item.children = menu_item.children.filter(function (child_item) {
            return child_item.requires_role === undefined || self.user_has_role(child_item.requires_role);

          });

          if (menu_item.children.length === 1) {

            menu[index] = menu_item.children[0];

          }

        }

      });


      menu = menu.filter(itm => !itm.children || itm.children.length !== 0);


      return menu;

    }

  },

  data() {
    return {

      server: null,
      items: [
        {icon: 'mdi-view-dashboard', text: this.$t('navigation.primary.dashboard'), to: '/'},
        {icon: 'mdi-account-multiple', text: this.$t('navigation.primary.customers'), to: '/customers', requires_role: 'customer_manager',},

        { text: this.$t('navigation.primary.order_management'), icon: 'mdi-truck-delivery',
          children: [
            {icon: 'mdi-truck-delivery', text: this.$t('navigation.primary.orders'), to: '/orders/orders', requires_role: 'order_manager'},
            {icon: 'mdi-truck-delivery', text: this.$t('navigation.primary.shipping'), to: '/orders/shipping', requires_role: ['order_manager','shipping_manager']},
            {icon: 'mdi-parcel', text: this.$t('navigation.primary.parcels'), to: '/orders/parcels', requires_role: 'order_manager'},
            {icon: 'mdi-truck', text: this.$t('navigation.primary.suppliers'), to: '/suppliers', requires_role: 'master'},
          ]
        },

        { icon: 'mdi-receipt', text: this.$t('navigation.primary.payment_management'),
          children: [
            {icon: 'mdi-receipt', text: this.$t('navigation.primary.invoices'), to: '/invoices', requires_role: 'order_manager'},
            {icon: 'mdi-receipt',text: this.$t('navigation.primary.transactions'), to: '/payments', requires_role: 'order_manager'},
          ]
        },

        {  icon: 'mdi-shopping', text: this.$t('navigation.primary.product_management'),
          children: [
            { icon: 'mdi-shopping', text: this.$t('navigation.primary.products'), to: '/products', requires_role: 'product_manager'},
            { icon: 'mdi-shopping', text: this.$t('navigation.primary.product_tags'), to: '/product-tags', requires_role: 'product_manager'},
            { icon: 'mdi-shopping', text: this.$t('navigation.primary.product_categories'), to: '/product-categories', requires_role: 'product_manager'},
            { icon: 'mdi-shopping', text: this.$t('navigation.primary.product_attributes'), to: '/product-attributes', requires_role: 'product_manager'},
            { icon: 'mdi-shopping', text: this.$t('navigation.primary.product_media'), to: '/product-media', requires_role: ['product_manger','translator']},
          ]
        },

        { icon: 'mdi-translate',text: this.$t('navigation.primary.translations'), to: '/translations', requires_role: 'translator', },

        { icon: 'mdi-cogs', text: this.$t('navigation.primary.administration'),
          children: [
            {icon: 'mdi-cogs', text: this.$t('navigation.primary.shops'), to: '/shops', requires_role: 'admin'},
            {icon: 'mdi-cogs', text: this.$t('navigation.primary.users'), to: '/admin/users', requires_role: 'admin'},
            {icon: 'mdi-cogs', text: this.$t('navigation.primary.cronjobs'), to: '/admin/cron', requires_role: 'admin'},
            {icon: 'mdi-cogs', text: this.$t('navigation.primary.webhooks'), to: '/admin/webhooks', requires_role: 'admin'},
            {icon: 'mdi-cogs', text: this.$t('navigation.primary.exports'), to: '/admin/export', requires_role: 'admin'},
            {icon: 'message', text: this.$t('navigation.primary.api_log'), to: '/admin/api-log', requires_role: 'admin'},
            {icon: 'message', text: this.$t('navigation.primary.sync-queue'), to: '/admin/sync-queue', requires_role: 'admin'},
            {icon: 'message', text: this.$t('navigation.primary.communication'), to: '/admin/communication', requires_role: 'admin'},
          ]
        },


      ],

    }
  },

  methods: {


    user_has_role(role = null) {

      let self = this;
      if (role === null || role === '') {return true;}

      if(Array.isArray(role)){

        for(let i = 0; i < role.length; i++){

          if(self.user_has_role(role[i]) === true){

            return true;

          }

        }

        return false;

      } else {

        if (self.session.getUserRoles.indexOf(role) < 0) {
          return false;
        }

        return true;

      }



    },

  },


}


</script>

