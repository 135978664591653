<template>
  <div>

    <primary-navigation></primary-navigation>

    <v-app-bar app flat v-if="session.app_bar" class="primary-app-bar">
      <v-app-bar-nav-icon @click.stop="session.togglePrimaryMenu()">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <primary-breadcrumb></primary-breadcrumb>

      <v-spacer></v-spacer>

      <user_background_jobs></user_background_jobs>
      <user_menu></user_menu>

    </v-app-bar>


    <v-main v-if="session.user_data != null" style="z-index:2; position: relative;">
      <router-view></router-view>
    </v-main>

  </div>
</template>

<script>

import {sessionStore} from '@/store/pinia/sessionStore'
import PrimaryNavigation from './_primary_navigation'
import user_menu from './user_menu'
import user_background_jobs from './user_background_jobs'
import PrimaryBreadcrumb from "./primary-breadcrumb.vue";
import AppLogo from "./AppLogo.vue";


export default {


  name: 'interface',
  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },

  components: {
    AppLogo,
    PrimaryBreadcrumb,


    user_menu,
    PrimaryNavigation,
    user_background_jobs,


  },
  data() {
    return {

      drawer: false,
      title: null

    }
  },
  mounted: function () {

    if (localStorage.getItem('primary_drawer_open') == 'yes') {
      this.drawer = true;
    } else {
      this.drawer = false;
    }

  },

  watch: {},
  methods: {},
  computed: {},

  metaInfo() {
    return {

      titleTemplate: (titleChunk) => {

        var self = this;
        self.title = titleChunk;
        return titleChunk ? `${titleChunk} - PLING` : 'PLING';

      }

    }
  },
}
</script>


<style>


.ql-editor ul, .ql-editor ol {

  margin-top: 20px !important;
  margin-bottom: 20px !important;

}

.ql-editor p {


  padding-top: 10px !important;
  padding-bottom: 10px !important;


}

body {

  background-color: #000;

}

.v-content, .v-content.container {

  max-width: 100%;


}

.v-main__wrap .container {

  max-width: 100%;
  padding: 30px;

}


.v-input {

  margin: 5px;

}

</style>
