<template>


  <div>


    <v-breadcrumbs
        :items="breadcrumb"

    ></v-breadcrumbs>


  </div>

</template>


<script>

import {sessionStore} from '@/store/pinia/sessionStore'


export default {

  name: 'primary-breadcrumb',
  setup() {

    const session = sessionStore();

    return {
      // you can return the whole store instance to use it in the template
      session,
    }

  },
  metaInfo() {
    return {

      titleTemplate: (titleChunk) => {

        var self = this;
        self.title = titleChunk;
        return titleChunk ? `${titleChunk} - PLING` : 'PLING';

      }

    }
  },
  computed: {

    breadcrumb: function(){

      let self = this;
      let breadcrumb = JSON.parse(JSON.stringify(self.session.breadcrumb));

      if(breadcrumb.length === 0){


        return [{text: self.title}];



      }

      return breadcrumb;


    },

  },
  data: () => ({
    title: null,
    items: [
        {text: 'Dashboard', disabled: false, href: 'breadcrumbs_dashboard'},
    ],
  }),

}

</script>
